<template>
  <v-card>
    <v-card-title>
      <h3>Generar Token</h3>
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="name"
        label="Nombre"
        hint="El nombre ayudara a identificar el token"
        persistent-hint
      ></v-text-field>
      <v-select
        :items="items"
        v-model="origin"
        label="Origen"
        hint="El origen del token es el lugar donde se utilizara"
        persistent-hint
      ></v-select>
      <v-btn
        class="mt-2"
        small
        outlined
        @click="generateToken"
        :disabled="!name || !origin"
        >Generar</v-btn
      >
      <div class="token__container rounded-lg ma-2 pa-3">
        <div>
          <icon-copy-to-clip-board :text="token" />
        </div>
        Token: {{ token }}
      </div>
      <!-- privadidad -->
      <v-alert type="warning" dense outlined>
        El token es una llave de acceso a la API de Inmo, por favor no compartas
        este token con nadie.
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import { generateInmoToken } from "../../../../api/mnt-api";
import IconCopyToClipBoard from "../../../common/IconCopyToClipBoard.vue";
export default {
  name: "GenerateToken",

  components: {
    IconCopyToClipBoard
  },
  props: {
    groupId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      items: ["zapier", "website"],
      name: "",
      origin: "",
      token: ""
    };
  },
  methods: {
    async generateToken() {
      const data = {
        name: this.name,
        origin: this.origin,
        accessGroup: this.groupId
      };
      const res = await generateInmoToken(data);
      this.token = res.token;
    }
  }
};
</script>
<style>
.token__container {
  position: relative;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
}
</style>
