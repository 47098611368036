import {
  GetRealEstateDevelopmentDetail,
  getRealEstateDevAssgignApi,
  UpdateRealEstateDevelopment
} from "@/api/real-estate-development-api.js";

export default {
  namespaced: true,
  state: {
    realEstateAssign: [],
    loadingRealEstateAssign: false,
    actualItem: {},
    loading: false
  },
  getters: {},
  mutations: {
    setItems(state, realEstateDevelopments) {
      // actualiza la información de los leads en Vuex
      state.items = realEstateDevelopments;
    },
    setActualItem(state, realEstateDevelopment) {
      state.actualItem = realEstateDevelopment;
    },
    setLoading(state, loading) {
      // establece que se esta cargando información
      // ej si se registra un nuevo lead, manda un loading a la tabla de leads
      state.loading = loading;
    },
    setRealEstateAssign(state, v) {
      state.realEstateAssign = v;
    },
    setLoadingRealEstateAssign(state, v) {
      state.loadingRealEstateAssign = v;
    }
  },
  actions: {
    // obtiene el lead del API C28 y lo asigna como item actual
    fetchActualItem({ commit }, leadId) {
      try {
        return new Promise(resolve => {
          // consultar API C28
          // actualizar valor VUEX
          GetRealEstateDevelopmentDetail(leadId)
            .then(res => {
              commit("setActualLead", res.Lead);
              resolve();
            })
            .catch(err => {
              throw new Error(err);
            });
        });
      } catch (err) {
        return Promise.reject(err);
      }
    },
    getRealEstateDevAssgign({ commit }) {
      commit("setLoadingRealEstateAssign", true);
      try {
        return new Promise(resolve => {
          getRealEstateDevAssgignApi()
            .then(res => {
              commit(
                "setRealEstateAssign",
                res.response.data.RealEstateDevelopment
              );
              commit("setLoadingRealEstateAssign", false);
              resolve(res);
            })
            .catch(err => {
              commit("setLoadingRealEstateAssign", false);
              throw new Error(err);
            });
        });
      } catch (error) {
        commit("setLoadingRealEstateAssign", false);
        return Promise.reject(error);
      }
    },
    fetchUpdateRealEstateDevelopmentExcel({ commit }, updateData) {
      try {
        let promises = [];

        if (!updateData.realEstateDevelopmentId)
          throw new Error("No se especificó parámetro realEstateDevelopmentId");

        let developmentId = updateData.realEstateDevelopmentId;
        let dataDevelopment = {};

        // evaluar que datos se van a editar
        if (updateData) {
          dataDevelopment = {
            media: {
              gallery: [],
              structure: {
                src: setValue(
                  updateData.media_structure_src,
                  updateData.media_structure_src,
                  ""
                )
              }
            },
            development_structure: {
              floors: setValue(
                updateData.development_structure_floors &&
                  Array.isArray(updateData.development_structure_floors),
                updateData.development_structure_floors,
                []
              ),
              towers: setValue(
                updateData.development_structure_towers &&
                  Array.isArray(updateData.development_structure_towers),
                updateData.development_structure_towers,
                []
              )
            },
            miscellaneous: {
              mapping_coords_structure: setValue(
                updateData.miscellaneous_mapping_coords_structure &&
                  Array.isArray(
                    updateData.miscellaneous_mapping_coords_structure
                  ),
                updateData.miscellaneous_mapping_coords_structure,
                []
              )
            }
          };
          promises.push(
            UpdateRealEstateDevelopment(dataDevelopment, developmentId)
          );
        }
        if (promises.length === 0) return Promise.reject("Nada por actualizar");
        return Promise.all(promises)
          .then(values => {
            // actualizar tabla de prpiedades
            commit("setLoading", true);
            return Promise.resolve(values);
          })
          .catch(err => {
            throw new Error(err);
          });
      } catch (err) {
        return Promise.reject(err);
      }
    }
  }
};
const setValue = (condition, value, def) => {
  if (condition) {
    return value;
  }
  if (!condition && def) {
    return def;
  }
};
