<template>
  <helper-message>
    <v-icon
      class="ml-1"
      v-bind="computedSizeProps"
      :size="size"
      :color="color"
      @click="copyText"
      :disabled="disabled"
    >
      mdi-content-copy
    </v-icon>

    <template #message>
      <span>Copiar al portapapeles</span>
    </template>
  </helper-message>
</template>

<script>
import HelperMessage from "./HelperMessage.vue";

const SIZE = ["x-small", "small", "dense", "large", "x-large"];

export default {
  name: "IconCopyToClipBoard",
  components: {
    HelperMessage
  },
  props: {
    text: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "primary"
    },
    size: {
      type: String,
      default: "",
      validator: value => SIZE.includes(value) || value === ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedSizeProps() {
      return {
        "x-small": this.size === "x-small",
        small: this.size === "small",
        dense: this.size === "dense",
        large: this.size === "large",
        "x-large": this.size === "x-large"
      };
    }
  },
  methods: {
    async copyText() {
      try {
        await navigator.clipboard.writeText(this.text);
        this.$snotify.success("Copiado al portapapeles");
      } catch (error) {
        this.$snotify.error("Error al copiar al portapapeles");
      }
    }
  }
};
</script>
