<template>
  <v-card v-if="sys">
    <div class="d-flex justify-space-between align-center">
      <div>
        <v-card-title>Usuario {{ sys.name }}</v-card-title>
        <v-card-subtitle>Se encuentra en:</v-card-subtitle>
      </div>

      <div class="ma-5">
        <img :src="logo" contain height="65px" />
      </div>
    </div>
    <v-card-text>
      <v-autocomplete
        v-model="value"
        :items="inmosList"
        label="Inmobiliaria"
        item-text="name"
        item-value="_id"
        dense
        @change="changeValue"
      >
      </v-autocomplete>
    </v-card-text>
  </v-card>
  <v-skeleton-loader v-else v-bind="attrs" type="article" />
</template>

<script>
import { mapState } from "vuex";
import { getUserSys, changeInmoMoveSys } from "@/api/mnt-api";

export default {
  name: "MoveUserSys",
  data() {
    return {
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2
      },
      value: null,
      sys: null,
      previousValue: null // Añadimos este data para guardar el valor anterior
    };
  },
  async created() {
    const res = await getUserSys();
    this.sys = res.data.sys;
    this.value = this.sys.real_estate_group_id;
    this.previousValue = this.value; // Guardamos el valor inicial
  },
  computed: {
    ...mapState("root", {
      inmosList: state =>
        state.inmosList.sort((a, b) => a.name.localeCompare(b.name))
    }),
    logo() {
      const inmo = this.inmosList.find(i => i._id === this.value);
      if (inmo?.media?.logo?.src) {
        return inmo.media.logo.src;
      }
      //this.background = "primary";
      return require("@/assets/logoC28_primary.png");
    }
  },
  methods: {
    changeValue() {
      if (!this.value) return;

      this.$confirmation(
        "Confirmar",
        "¿Estás seguro de cambiar de inmobiliaria?",
        () => this.changeInmo(),
        () => this.cancelChange() // Añadimos un callback para el botón de cancelar
      );
    },
    async changeInmo() {
      const res = await changeInmoMoveSys(this.sys._id, this.value);

      if (!res.data)
        return this.$snotify.error(
          res.Message || "Error al cambiar de inmobiliaria",
          "Error :|"
        );
      this.$snotify.success(res.Message, "Success");
      this.previousValue = this.value; // Actualizamos el valor anterior
    },
    cancelChange() {
      this.value = this.previousValue; // Restauramos el valor anterior
    }
  }
};
</script>
